import React from 'react';
import { graphql } from 'gatsby';
import { shape } from 'prop-types';
import { SocialIcon } from 'react-social-icons';
import moment from 'moment';

import Layout from '../components/layout';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';

const propTypes = {
  data: shape({}).isRequired,
  location: shape({}).isRequired,
};

const PostTemplate = ({ data, location }) => {
  const { strapiPost: post, site } = data;
  const iconStyle = { height: 25, width: 25 };
  const url = `${site.siteMetadata.siteUrl}${location.pathname}`;

  return (
    <Layout title={post.title}>
      <section className="content-wrapper post-intern">
        <Grid justifyContent="center" alignItems="center">
          <GridItem md={8}>
            <h1>
              { post.title }
            </h1>
            <p><small>{`Posted on ${moment(post.created_at).format('MMMM Do YYYY')}`}</small></p>

            { /* eslint-disable-next-line react/no-danger */ }
            <section dangerouslySetInnerHTML={{ __html: post.content }} />

            <Grid justifyContent="start" alignItems="center">
              <div className="social-icons">
                <SocialIcon
                  url={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                  target="_blank"
                  style={iconStyle}
                />
              </div>
              <div className="social-icons">
                <SocialIcon
                  url={`https://twitter.com/share?url=${url}`}
                  target="_blank"
                  style={iconStyle}
                />
              </div>
              <div className="social-icons">
                <SocialIcon
                  url={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
                  target="_blank"
                  style={iconStyle}
                />
              </div>
              <div className="social-icons">
                <SocialIcon
                  url={`mailto:?subject=I wanted you to see this post&amp;body=Check out this post ${url}.`}
                  target="_blank"
                  style={iconStyle}
                />
              </div>
            </Grid>
          </GridItem>
        </Grid>
      </section>
    </Layout>
  );
};

PostTemplate.propTypes = propTypes;

export default PostTemplate;

export const query = graphql`
  query PostTemplate($slug: String!) {
    strapiPost(slug: { eq: $slug }) {
      id
      title
      type
      content
      created_at
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
